import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import SEO from '../layouts/Seo'
const NotFoundPage = () => {
  return (
    <>
      <SEO
        title='Erez Kaynak - Sayfa Bulunamadı'
        description='Paslanmaz, galvanize yada çelik telden, tel kalınlığı ve ebat, göz aralığı fark etmeksizin punta kaynaklı hasır tel (çesan) imalatı yapılır.Paslanmaz elek teli ve paslanmaz filtre imalatçısı'
        keywords='hasır tel,hasır tel fiyatları,hasır tel imalatı,paslanmaz hasır tel,hasır telleri,puntalı hasır tel,punta kaynaklı hasır tel,puntalı hasır tel fiyatları,hasır,tel,hasır tel imalatçısı,özel hasır tel,galvaniz hasır tel,hasır tel istanbul,tel hasır,kafes tel,tel hasır fiyatları,kafes tel fiyatları,hasır tel fiyatı,filtre imalatı,elek imalatı,paslanmaz filtre,paslanmaz elek'
      />
      <Box p={3} textAlign='center'>
        <Typography variant='h4' component='h1' color='secondary'>
          <Box fontWeight={500}>404 - Sayfa Bulunamadı</Box>
        </Typography>
        <p style={{ fontSize: '1.2em' }}>Ulaşmaya çalıştığınız sayfa mevcut değildir.</p>
      </Box>
    </>
  )
}
export default NotFoundPage
